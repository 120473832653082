<template lang="">
  <div class="submit-bar__bar">
    <van-checkbox
      v-model="localChecked"
      shape="round"
      @change="(val) => $emit('change', val)"
      >{{ checked ? "反选" : "全选" }}</van-checkbox
    >
    <van-button type="info" round @click="$emit('submit')"
      >批量{{ title }}</van-button
    >
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "派单",
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    checked(newVal) {
      this.localChecked = newVal; // 监听 prop 的变化
    },
  },
  data() {
    return {
      localChecked: false,
    };
  },
};
</script>
<style lang="less" scoped>
.submit-bar__bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  font-size: 14px;
}
</style>
